.birdsBox {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: url('../../images/birds_bg.jpeg') no-repeat center center / cover;
	cursor: pointer;
}
.menuList {
	display: flex;
	column-gap: 16px;
	row-gap: 16px;
	flex-wrap: wrap;
	justify-content: center;
	color: white;
	padding: 32px;
	transform: translate(0, 0, 0);
	span {
		cursor: pointer;
	}
}